import React, { useState, useEffect } from 'react';
import { Button, Header, Segment, Message, Label, Icon, Form, Confirm } from 'semantic-ui-react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { formatDate } from '../../../utils/dateUtils';

interface Test {
  id: string;
  testName: string;
  type: 'closeBook' | 'verseMemorization';
  totalGrade: number;
  criteria?: {
    shortAnswer: { description: string; grade: number };
    longAnswer: { description: string; grade: number };
  };
}

interface GradeCloseBookTestProps {
  test: Test;
  moduleId: string;
  submissionId: string;
  onBack: () => void;
}

const GradeCloseBookTest: React.FC<GradeCloseBookTestProps> = ({ test, moduleId, submissionId, onBack }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [submission, setSubmission] = useState<any>(null);
  const [shortAnswerGrade, setShortAnswerGrade] = useState<number>(0);
  const [longAnswerGrade, setLongAnswerGrade] = useState<number>(0);
  const [totalGrade, setTotalGrade] = useState<number>(0);
  const [comments, setComments] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [isGraded, setIsGraded] = useState(false);

  useEffect(() => {
    const fetchSubmission = async () => {
      try {
        const submissionDoc = await getDoc(
          doc(db, 'modules', moduleId, 'tests', test.id, 'submissions', submissionId)
        );

        if (submissionDoc.exists()) {
          const data = submissionDoc.data();
          setSubmission(data);
          
          // If already graded, set the existing grades and comments
          if (data.gradingStatus === 'graded') {
            if (test.type === 'closeBook') {
              setShortAnswerGrade(data.grades?.shortAnswer || 0);
              setLongAnswerGrade(data.grades?.longAnswer || 0);
              setTotalGrade(data.grade || 0);
            } else {
              setTotalGrade(data.grade || 0);
            }
            setComments(data.graderComments || '');
            setIsGraded(true);
          }
        }
      } catch (err) {
        console.error('Error fetching submission:', err);
        setError('Failed to load submission');
      } finally {
        setLoading(false);
      }
    };

    fetchSubmission();
  }, [moduleId, test.id, submissionId, test.type]);

  const handleGradeChange = (type: 'shortAnswer' | 'longAnswer', value: number) => {
    if (type === 'shortAnswer') {
      setShortAnswerGrade(value);
      setTotalGrade(value + longAnswerGrade);
    } else {
      setLongAnswerGrade(value);
      setTotalGrade(shortAnswerGrade + value);
    }
  };

  const isSubmitDisabled = () => {
    if (isGraded) return true;

    if (test.type === 'closeBook') {
      const isShortAnswerValid = shortAnswerGrade >= 0 && shortAnswerGrade <= test.criteria!.shortAnswer.grade;
      const isLongAnswerValid = longAnswerGrade >= 0 && longAnswerGrade <= test.criteria!.longAnswer.grade;
      return !isShortAnswerValid || !isLongAnswerValid || !comments.trim();
    } else {
      return totalGrade < 0 || totalGrade > test.totalGrade || !comments.trim();
    }
  };

  const handleSubmit = async () => {
    try {
      const submissionRef = doc(db, 'modules', moduleId, 'tests', test.id, 'submissions', submissionId);
      
      const updateData = {
        gradingStatus: 'graded',
        gradedAt: new Date(),
        graderComments: comments,
        ...(test.type === 'closeBook' 
          ? {
              grades: {
                shortAnswer: shortAnswerGrade,
                longAnswer: longAnswerGrade
              },
              grade: shortAnswerGrade + longAnswerGrade
            }
          : {
              grade: totalGrade
            }
        )
      };

      await updateDoc(submissionRef, updateData);
      setIsGraded(true);
      setShowConfirm(false);
      
      // Show success message and return to list after a short delay
      setTimeout(() => {
        onBack();
      }, 1000);
    } catch (err) {
      console.error('Error submitting grades:', err);
      setError('Failed to submit grades');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!submission) return <Message negative>Submission not found</Message>;

  return (
    <div>
      <Button
        color='grey'
        icon
        labelPosition='left'
        onClick={onBack}
        style={{ marginBottom: '1rem' }}
      >
        <Icon name='arrow left' />
        Back to Submissions
      </Button>

      <Header as="h2">Grade Submission #{submissionId.slice(-6)}</Header>

      {error && <Message negative>{error}</Message>}

      <Segment>
        <Header as="h3">Submission Details</Header>
        <p><strong>Submitted on:</strong> {formatDate(submission.submittedAt.toDate())}</p>
        <Button
          as="a"
          href={submission.pdfUrl}
          target="_blank"
          rel="noopener noreferrer"
          primary
        >
          View Submitted PDF
        </Button>
      </Segment>

      <Form>
        <Segment>
          <Header as="h3">Grading</Header>
          
          {test.type === 'closeBook' ? (
            <>
              <Form.Field>
                <label>Short Answer Questions (Total: {test.criteria!.shortAnswer.grade} points)</label>
                <Form.Input
                  type="number"
                  value={shortAnswerGrade}
                  onChange={(e) => handleGradeChange('shortAnswer', Number(e.target.value))}
                  min={0}
                  max={test.criteria!.shortAnswer.grade}
                  label={{ basic: true, content: `/ ${test.criteria!.shortAnswer.grade}` }}
                  labelPosition="right"
                  disabled={isGraded}
                  style={isGraded ? {
                    opacity: 1,
                    backgroundColor: '#fff',
                    color: '#000',
                    border: '1px solid #ddd'
                  } : undefined}
                  error={
                    shortAnswerGrade > test.criteria!.shortAnswer.grade
                      ? {
                          content: `Maximum grade allowed is ${test.criteria!.shortAnswer.grade}`,
                          pointing: 'above'
                        }
                      : false
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>Long Answer Questions (Total: {test.criteria!.longAnswer.grade} points)</label>
                <Form.Input
                  type="number"
                  value={longAnswerGrade}
                  onChange={(e) => handleGradeChange('longAnswer', Number(e.target.value))}
                  min={0}
                  max={test.criteria!.longAnswer.grade}
                  label={{ basic: true, content: `/ ${test.criteria!.longAnswer.grade}` }}
                  labelPosition="right"
                  disabled={isGraded}
                  style={isGraded ? {
                    opacity: 1,
                    backgroundColor: '#fff',
                    color: '#000',
                    border: '1px solid #ddd'
                  } : undefined}
                  error={
                    longAnswerGrade > test.criteria!.longAnswer.grade
                      ? {
                          content: `Maximum grade allowed is ${test.criteria!.longAnswer.grade}`,
                          pointing: 'above'
                        }
                      : false
                  }
                />
              </Form.Field>

              <Message info>
                <Message.Header>Grade Breakdown</Message.Header>
                <p>Short Answer: {test.criteria!.shortAnswer.grade} points</p>
                <p>Long Answer: {test.criteria!.longAnswer.grade} points</p>
                <p>Total Possible Grade: {test.criteria!.shortAnswer.grade + test.criteria!.longAnswer.grade} points</p>
              </Message>
            </>
          ) : (
            <>
              <Form.Field>
                <label>Total Grade (Maximum: {test.totalGrade} points)</label>
                <Form.Input
                  type="number"
                  value={totalGrade}
                  onChange={(e) => setTotalGrade(Number(e.target.value))}
                  min={0}
                  max={test.totalGrade}
                  label={{ basic: true, content: `/ ${test.totalGrade}` }}
                  labelPosition="right"
                  disabled={isGraded}
                  style={isGraded ? {
                    opacity: 1,
                    backgroundColor: '#fff',
                    color: '#000',
                    border: '1px solid #ddd'
                  } : undefined}
                  error={
                    totalGrade > test.totalGrade
                      ? {
                          content: `Maximum grade allowed is ${test.totalGrade}`,
                          pointing: 'above'
                        }
                      : false
                  }
                />
              </Form.Field>
            </>
          )}
        </Segment>

        <Segment>
          <Header as="h3">Comments</Header>
          <Form.TextArea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            disabled={isGraded}
          />
        </Segment>

        <Button
          color='green'
          onClick={() => setShowConfirm(true)}
          disabled={isSubmitDisabled()}
        >
          Submit Grades
        </Button>
      </Form>

      <Confirm
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={handleSubmit}
      />
    </div>
  );
};

export default GradeCloseBookTest;