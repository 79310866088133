import React, { useState, useEffect } from 'react';
import { Table, Loader, Message } from 'semantic-ui-react';
import { 
  collection, 
  getDocs, 
  query, 
  orderBy, 
  getDoc,
  doc as firestoreDoc
} from 'firebase/firestore';
import { db } from '../../firebase/config';

interface ViewAttendanceProps {
  moduleId: string;
  students: {
    id: string;
    firstName: string;
    lastName: string;
    initiatedName?: string;
  }[];
}

interface AttendanceRecord {
  date: Date;
  attendees: Record<string, boolean>;
  updatedBy: string;
  updatedAt: Date;
}

interface UserDetails {
  initiatedName?: string;
  firstName?: string;
  lastName?: string;
}

const ViewAttendance: React.FC<ViewAttendanceProps> = ({ moduleId, students }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [attendanceRecords, setAttendanceRecords] = useState<(AttendanceRecord & { markerName: string })[]>([]);

  useEffect(() => {
    const fetchAttendanceRecords = async () => {
      try {
        const q = query(
          collection(db, `modules/${moduleId}/attendance`), 
          orderBy('date', 'asc')
        );
        const querySnapshot = await getDocs(q);
        
        const recordsPromises = querySnapshot.docs.map(async docSnapshot => {
          const data = docSnapshot.data();
          
          // Fetch user details who marked the attendance
          let markerName = 'Unknown';
          if (data.updatedBy) {
            const userDocRef = firestoreDoc(db, 'users', data.updatedBy);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
              const userData = userDoc.data() as UserDetails;
              markerName = userData.initiatedName || 
                          (userData.firstName && userData.lastName ? 
                           `${userData.firstName} ${userData.lastName}` : 
                           'Unknown');
            }
          }

          return {
            date: data.date.toDate(),
            attendees: data.attendees,
            updatedBy: data.updatedBy,
            updatedAt: data.updatedAt?.toDate(),
            markerName
          };
        });

        const records = await Promise.all(recordsPromises);
        setAttendanceRecords(records);
      } catch (err) {
        console.error('Error fetching attendance:', err);
        setError('Failed to load attendance records');
      } finally {
        setLoading(false);
      }
    };

    fetchAttendanceRecords();
  }, [moduleId]);

  if (loading) return <Loader active>Loading attendance records...</Loader>;
  if (error) return <Message negative>{error}</Message>;

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  };

  const calculateTotalPresent = (studentId: string) => {
    return attendanceRecords.reduce((total, record) => {
      return total + (record.attendees[studentId] ? 1 : 0);
    }, 0);
  };

  const isEligibleForCBT = (studentId: string) => {
    const totalAbsent = attendanceRecords.length - calculateTotalPresent(studentId);
    return totalAbsent <= 3;
  };

  // Sort students by first name
  const sortedStudents = [...students].sort((a, b) => 
    a.firstName.localeCompare(b.firstName)
  );

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      height: 'auto'
    }}>
      <div style={{
        overflowX: 'scroll',
        overflowY: 'visible',
        margin: 0,
        padding: 0,
        width: '100%'
      }}>
        <div style={{ minWidth: '1000px', width: 'auto' }}>
          <Table celled structured style={{ minWidth: '800px' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell rowSpan={2}>S.No</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>Student Name</Table.HeaderCell>
                {attendanceRecords.map(record => (
                  <Table.HeaderCell key={record.date.toISOString()}>
                    {formatDate(record.date)}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell rowSpan={2}>Total Present</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>Eligible for CBT</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                {attendanceRecords.map(record => (
                  <Table.HeaderCell key={`marker-${record.date.toISOString()}`} textAlign="center">
                    Marked by: {record.markerName}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {sortedStudents.map((student, index) => (
                <Table.Row key={student.id}>
                  <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                  <Table.Cell>
                    {student.initiatedName || `${student.firstName} ${student.lastName}`}
                  </Table.Cell>
                  {attendanceRecords.map(record => (
                    <Table.Cell 
                      key={`${student.id}-${record.date.toISOString()}`}
                      positive={record.attendees[student.id]}
                      negative={!record.attendees[student.id]}
                      textAlign="center"
                    >
                      {record.attendees[student.id] ? '✓' : '✗'}
                    </Table.Cell>
                  ))}
                  <Table.Cell textAlign="center">
                    {calculateTotalPresent(student.id)}/{attendanceRecords.length}
                  </Table.Cell>
                  <Table.Cell 
                    textAlign="center"
                    positive={isEligibleForCBT(student.id)}
                    negative={!isEligibleForCBT(student.id)}
                  >
                    {isEligibleForCBT(student.id) ? 'Yes' : 'No'}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ViewAttendance; 