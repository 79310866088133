import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';
import OpenBookTest from './OpenBookTest';
import CloseBookTest from './CloseBookTest';

const CreateTest: React.FC<{ moduleId: string; onTestCreated: () => void }> = ({ moduleId, onTestCreated }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const panes = [
    { menuItem: 'Open Book Test', render: () => <Tab.Pane><OpenBookTest moduleId={moduleId} onTestCreated={onTestCreated} /></Tab.Pane> },
    { menuItem: 'Close Book Test', render: () => <Tab.Pane><CloseBookTest moduleId={moduleId} onTestCreated={onTestCreated} /></Tab.Pane> },
  ];

  return (
    <div>
      <h2>Create Test</h2>
      <Tab 
        panes={panes} 
        activeIndex={activeIndex}
        onTabChange={(_, data) => setActiveIndex(data.activeIndex as number)}
      />
    </div>
  );
};

export default CreateTest;