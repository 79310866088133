import React, { useState, useEffect } from 'react';
import { Card, Button, Header, Segment, Label, Icon, Loader, Message } from 'semantic-ui-react';
import { collection, query, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { formatDate } from '../../../utils/dateUtils';
import GradeCloseBookTest from './GradeCloseBookTest';

interface Test {
  id: string;
  testName: string;
  type: 'closeBook' | 'verseMemorization';
  totalGrade: number;
  criteria?: {
    shortAnswer: { description: string; grade: number };
    longAnswer: { description: string; grade: number };
  };
}

interface Submission {
  id: string;
  studentId: string;
  submittedAt: Date;
  gradingStatus: 'pending' | 'graded';
  grade?: number;
  pdfUrl: string;
  assignedGraderId?: string;
}

interface GradeCloseBookListProps {
  test: Test;
  moduleId: string;
  onBack: () => void;
}

const GradeCloseBookList: React.FC<GradeCloseBookListProps> = ({ test, moduleId, onBack }) => {
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [selectedSubmission, setSelectedSubmission] = useState<string | null>(null);

  const fetchSubmissions = async () => {
    if (!user) return;

    try {
      const submissionsRef = collection(db, 'modules', moduleId, 'tests', test.id, 'submissions');
      const querySnapshot = await getDocs(submissionsRef);

      const fetchedSubmissions = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          submittedAt: doc.data().submittedAt.toDate()
        } as Submission))
        .filter(submission => submission.assignedGraderId === user.uid);

      setSubmissions(fetchedSubmissions);
    } catch (err) {
      console.error('Error fetching submissions:', err);
      setError('Failed to load submissions');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubmissions();
  }, [moduleId, test.id, user]);

  if (loading) return <Loader active>Loading submissions...</Loader>;

  if (selectedSubmission) {
    return (
      <GradeCloseBookTest
        test={test}
        moduleId={moduleId}
        submissionId={selectedSubmission}
        onBack={() => {
          setSelectedSubmission(null);
          fetchSubmissions(); // Refresh submissions when returning
        }}
      />
    );
  }

  const getPartialStudentId = (studentId: string): string => {
    if (studentId.length <= 8) return studentId;
    return `${studentId.substring(0, 4)}...${studentId.substring(studentId.length - 4)}`;
  };

  return (
    <div>
      <Button
        color='grey'
        icon
        labelPosition='left'
        onClick={onBack}
        style={{ marginBottom: '1rem' }}
      >
        <Icon name='arrow left' />
        Back to Test
      </Button>

      <Header as="h2">Grade {test.testName}</Header>

      {error && <Message negative>{error}</Message>}

      {submissions.length === 0 ? (
        <Message info>
          <Message.Header>No Submissions</Message.Header>
          <p>No submissions have been assigned to you yet.</p>
        </Message>
      ) : (
        <Card.Group>
          {submissions.map(submission => (
            <Card fluid key={submission.id}>
              <Card.Content>
                <Card.Header>
                  Submission #{submission.id.slice(-6)}
                </Card.Header>
                <Card.Meta>
                  Submitted: {formatDate(submission.submittedAt)}
                </Card.Meta>
                <Card.Description>
                  <Label color={submission.gradingStatus === 'graded' ? 'green' : 'orange'}>
                    Status: {submission.gradingStatus}
                  </Label>
                  {submission.gradingStatus === 'graded' && (
                    <Label color="blue">
                      Grade: {submission.grade}
                    </Label>
                  )}
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <div className='ui two buttons'>
                  <Button
                    primary
                    onClick={() => setSelectedSubmission(submission.id)}
                  >
                    {submission.gradingStatus === 'graded' ? 'View Grading' : 'Grade Submission'}
                  </Button>
                  <Button
                    as="a"
                    href={submission.pdfUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View PDF
                  </Button>
                </div>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      )}
    </div>
  );
};

export default GradeCloseBookList; 