import React, { useState, useEffect } from 'react';
import { Button, Header, Segment, Message, Confirm, List, Icon, Tab, Label } from 'semantic-ui-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../../firebase/config';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { stripHtml } from "string-strip-html";
import parse from 'html-react-parser';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

interface StudentSubmissionOBProps {
    moduleId: string;
    testId: string;
    questionIndex: number;
    question: Question;
    onBack: () => void;
}

interface Criteria {
    description: string;
    grade: number;
    actualGrade?: number;
}

interface Question {
    text: string;
    criteria: Criteria[];
}

interface SubmissionHistory {
    essay: string;
    submittedAt: Date;
    gradingStatus: 'graded';
    grade: number;
    criteriaGrades: Criteria[];
    graderComments: string;
    requiresRewrite: boolean;
    rewriteNumber: number;
    questionIndex: number;
    originalQuestionIndex: number;
}

const StudentSubmissionOB: React.FC<StudentSubmissionOBProps> = ({ moduleId, testId, questionIndex, question, onBack }) => {
    const [user] = useAuthState(auth);
    const [essay, setEssay] = useState('');
    const [savedEssay, setSavedEssay] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [otherSavedAnswers, setOtherSavedAnswers] = useState<boolean>(false);
    const [gradingInfo, setGradingInfo] = useState<{
        criteriaGrades?: Criteria[];
        graderComments?: string;
        grade?: number;
        requiresRewrite?: boolean;
    } | null>(null);
    const [submissionHistory, setSubmissionHistory] = useState<SubmissionHistory[]>([]);
    const [activeSubmissionTab, setActiveSubmissionTab] = useState(0);
    const [isRewrite, setIsRewrite] = useState(false);
    const [rewriteNumber, setRewriteNumber] = useState(0);

    // useEffect(() => {
    //     // Connect to functions emulator
    //     const functions = getFunctions();
    //     connectFunctionsEmulator(functions, 'localhost', 5001);
    // }, []);

    useEffect(() => {
        let isMounted = true; // Flag to prevent state updates if component is unmounted

        const fetchEssay = async () => {
            if (user?.uid) {
                try {
                    const submissionRef = doc(db, 'modules', moduleId, 'tests', testId, 'submissions', user.uid);
                    const submissionDoc = await getDoc(submissionRef);
                    if (submissionDoc.exists()) {
                        const data = submissionDoc.data();
                        const answer = data.answers?.find((ans: any) => ans.questionIndex === questionIndex);
                        if (answer) {
                            if (isMounted) {
                                setSavedEssay(answer.essay || '');
                                setIsEditing(false);
                                setIsSubmitted(answer.submitted || false);
                                setIsRewrite(answer.isRewrite || false);
                                setRewriteNumber(answer.rewriteNumber || 0);

                                // Add this section to handle grading info
                                if (answer.gradingStatus === 'graded') {
                                    setGradingInfo({
                                        criteriaGrades: answer.criteriaGrades,
                                        graderComments: answer.graderComments,
                                        grade: answer.grade,
                                        requiresRewrite: answer.requiresRewrite
                                    });
                                }

                                // Set submission history
                                if (answer.previousSubmissions) {
                                    setSubmissionHistory(answer.previousSubmissions);
                                }
                            }
                        } else {
                            if (isMounted) {
                                setIsEditing(true);
                            }
                        }
                        // Check for other saved answers
                        const otherAnswersExist = data.answers?.some((ans: any) => ans.questionIndex !== questionIndex);
                        if (isMounted) {
                            setOtherSavedAnswers(otherAnswersExist);
                        }
                    } else {
                        if (isMounted) {
                            setIsEditing(true);
                        }
                    }
                } catch (error) {
                    console.error("Error fetching essay:", error);
                    // Optionally set an error state here
                }
            }
        };

        fetchEssay();

        return () => {
            isMounted = false; // Cleanup flag on unmount
        };
    }, [user, moduleId, testId, questionIndex]); // Add necessary dependencies


    const handleSave = async () => {
        if (!user) return;
        setIsSaving(true);
        setError('');
        setSuccess('');
        try {
            const submissionRef = doc(db, 'modules', moduleId, 'tests', testId, 'submissions', user.uid);
            const submissionDoc = await getDoc(submissionRef);
            let answers = submissionDoc.exists() ? submissionDoc.data().answers || [] : [];

            const existingAnswerIndex = answers.findIndex((answer: any) => answer.questionIndex === questionIndex);
            const newAnswer = {
                essay,
                questionIndex,
                lastSaved: new Date(),
                submitted: false,
                isRewrite,
                rewriteNumber
            };

            if (existingAnswerIndex !== -1) {
                answers[existingAnswerIndex] = {
                    ...answers[existingAnswerIndex],
                    ...newAnswer
                };
            } else {
                answers.push(newAnswer);
            }

            await setDoc(submissionRef, { answers }, { merge: true });
            setSavedEssay(essay);
            setIsEditing(false);
            setSuccess('Essay saved successfully');
        } catch (err) {
            setError('Failed to save essay. Please try again.');
        }
        setIsSaving(false);
    };

    const handleSubmit = async () => {
        if (!user) return;
        setIsSubmitting(true);
        setError('');
        setSuccess('');
        try {
            const submissionRef = doc(db, 'modules', moduleId, 'tests', testId, 'submissions', user.uid);
            const submissionDoc = await getDoc(submissionRef);
            const currentData = submissionDoc.exists() ? submissionDoc.data() : {};
            
            // Get existing history or initialize empty array
            let previousSubmissions = currentData.answers?.[0]?.previousSubmissions || [];
            
            // If this is a rewrite and we have a current submission
            if (isRewrite && currentData.answers?.[0]) {
                const currentSubmission = currentData.answers[0];
                
                // If the current submission isn't already in history, add it
                if (previousSubmissions.length === 0) {
                    previousSubmissions = [{
                        essay: currentSubmission.essay,
                        submittedAt: currentSubmission.submittedAt?.toDate(),
                        gradingStatus: currentSubmission.gradingStatus,
                        grade: currentSubmission.grade,
                        criteriaGrades: currentSubmission.criteriaGrades,
                        graderComments: currentSubmission.graderComments,
                        requiresRewrite: currentSubmission.requiresRewrite,
                        rewriteNumber: currentSubmission.rewriteNumber || 0,
                        questionIndex: currentSubmission.questionIndex,
                        originalQuestionIndex: currentSubmission.questionIndex
                    }];
                }
            }

            // Create new submission
            const newSubmission = {
                essay,
                questionIndex,
                submitted: true,
                submittedAt: new Date(),
                gradingStatus: 'pending',
                isRewrite,
                rewriteNumber,
                previousSubmissions,
                originalQuestionIndex: previousSubmissions.length > 0 ? 
                    previousSubmissions[0].questionIndex : questionIndex
            };

            // Update the document with the new submission
            await setDoc(submissionRef, {
                answers: [newSubmission]
            });

            setSuccess('Essay submitted successfully' + 
                (isRewrite && previousSubmissions[0]?.questionIndex !== questionIndex ? 
                ' (Note: This rewrite answers a different question than your original submission)' : ''));
            setIsEditing(false);
            setIsSubmitted(true);
            setSavedEssay(essay);
        } catch (err) {
            console.error('Error submitting essay:', err);
            setError('Failed to submit essay. Please try again.');
        } finally {
            setIsSubmitting(false);
            setShowConfirm(false);
        }
    };

    const handleEdit = () => {
        setEssay(savedEssay);
        setIsEditing(true);
    };

    const handleStartRewrite = async () => {
        try {
            // Fetch the current submission to get the complete history
            const submissionRef = doc(db, 'modules', moduleId, 'tests', testId, 'submissions', user?.uid || '');
            const submissionDoc = await getDoc(submissionRef);
            const currentData = submissionDoc.exists() ? submissionDoc.data() : {};
            
            // Get the current submission and its history
            const currentSubmission = currentData.answers?.[0];
            let history = currentSubmission?.previousSubmissions || [];
            
            // If there's no history but there is a current submission, use it as the first item in history
            if (history.length === 0 && currentSubmission) {
                history = [{
                    essay: currentSubmission.essay,
                    submittedAt: currentSubmission.submittedAt,
                    gradingStatus: currentSubmission.gradingStatus,
                    grade: currentSubmission.grade,
                    criteriaGrades: currentSubmission.criteriaGrades,
                    graderComments: currentSubmission.graderComments,
                    requiresRewrite: currentSubmission.requiresRewrite,
                    rewriteNumber: currentSubmission.rewriteNumber,
                    questionIndex: currentSubmission.questionIndex,
                    originalQuestionIndex: currentSubmission.questionIndex
                }];
            }

            setSubmissionHistory(history);
            setIsRewrite(true);
            setRewriteNumber((currentSubmission?.rewriteNumber || 0) + 1);
            setIsEditing(true);
            setIsSubmitted(false);
            // Start with empty essay instead of previous submission for rewrite
            setEssay('');
            setSavedEssay('');
        } catch (error) {
            console.error("Error starting rewrite:", error);
            setError('Failed to start rewrite. Please try again.');
        }
    };

    const handleBack = () => {
        if (isEditing && essay !== savedEssay) {
            const confirmSave = window.confirm('You have unsaved changes. Do you want to save before leaving?');
            if (confirmSave) {
                handleSave().then(() => {
                    setSuccess('Essay saved successfully. You can now go back safely.');
                });
            } else {
                onBack();
            }
        } else {
            onBack();
        }
    };

    const wordCount = (text: string) => {
        const stripped = stripHtml(text).result.trim();
        return stripped.split(/\s+/).length;
    };

    const handleEssayChange = (value: string) => {
        const words = wordCount(value);
        setEssay(value);
        if (words > 500) {
            setError('You have exceeded the 500-word limit. Please reduce your essay length to submit.');
        } else {
            setError('');
        }
    };

    const renderSubmissionHistory = () => {
        const allSubmissions = [
            ...submissionHistory,
            isSubmitted ? {
                essay: savedEssay,
                submittedAt: new Date(),
                gradingStatus: gradingInfo?.grade !== undefined ? 'graded' : 'pending',
                grade: gradingInfo?.grade,
                criteriaGrades: gradingInfo?.criteriaGrades,
                graderComments: gradingInfo?.graderComments,
                requiresRewrite: gradingInfo?.requiresRewrite,
                rewriteNumber: rewriteNumber,
                questionIndex: questionIndex,
                originalQuestionIndex: submissionHistory[0]?.questionIndex || questionIndex
            } : null
        ].filter((submission): submission is SubmissionHistory => submission !== null);

        return (
            <Tab
                panes={allSubmissions.map((submission, index) => ({
                    menuItem: index === 0 ? 'Original Submission' : `Rewrite ${index}`,
                    render: () => (
                        <Tab.Pane>
                            <Segment>
                                <Header as="h4">
                                    {index === 0 ? 'Original Submission' : `Rewrite ${index}`}
                                    {submission.questionIndex !== submission.originalQuestionIndex && (
                                        <Label color="blue" style={{ marginLeft: '1rem' }}>
                                            Different Question Than Original
                                        </Label>
                                    )}
                                    {submission.gradingStatus === 'graded' ? (
                                        <Label color={submission.requiresRewrite ? 'red' : 'green'} style={{ marginLeft: '1rem' }}>
                                            {submission.requiresRewrite ? 'Requires Rewrite' : 'Graded'}
                                        </Label>
                                    ) : (
                                        <Label color="yellow" style={{ marginLeft: '1rem' }}>
                                            Pending
                                        </Label>
                                    )}
                                </Header>
                                <div className="rich-text-content">
                                    {parse(submission.essay)}
                                </div>
                                {submission.gradingStatus === 'graded' && (
                                    <>
                                        <Header as="h4">Grade: {submission.grade}</Header>
                                        <Header as="h4">Criteria Grades</Header>
                                        <List>
                                            {submission.criteriaGrades?.map((criterion, idx) => (
                                                <List.Item key={idx}>
                                                    <List.Content>
                                                        <List.Header>{criterion.description}</List.Header>
                                                        <List.Description>
                                                            Grade: {criterion.actualGrade} / {criterion.grade}
                                                        </List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            ))}
                                        </List>
                                        <Header as="h4">Grader Comments</Header>
                                        <Segment>
                                            {submission.graderComments || 'No comments provided'}
                                        </Segment>
                                    </>
                                )}
                            </Segment>
                        </Tab.Pane>
                    )
                }))}
                onTabChange={(_, data) => setActiveSubmissionTab(data.activeIndex as number)}
                activeIndex={activeSubmissionTab}
            />
        );
    };

    return (
        <div>            
            <div style={{ display: 'flex', justifyContent: 'left', marginTop: '20px' }}>
                {/* <Button onClick={handleBack} disabled={isSubmitting}>Back to Test</Button> */}
                <Button color='grey' icon disabled={isSubmitting} labelPosition='left' onClick={handleBack} style={{ marginBottom: '1rem' }}>
                    <Icon name='arrow left' />
                    Back to Test
                </Button>
            </div>
            <Header as="h2" style={{ marginTop: '10px' }}>
                Essay Submission
                {isRewrite && <Label color="blue" style={{ marginLeft: '1rem' }}>Rewrite #{rewriteNumber}</Label>}
            </Header>
            <Header as="h2" style={{ marginTop: '10px' }}>Question {questionIndex + 1}</Header>
            <Segment>
                <div className="rich-text-content">
                    {parse(question.text)}
                </div>
                <Header as="h5">Criteria</Header>
                <List>
                    {question.criteria.map((criterion, cIndex) => (
                        <List.Item key={cIndex}>
                            <List.Content>
                                <List.Header>{criterion.description}</List.Header>
                                <List.Description>Grade: {criterion.grade}</List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </Segment>

            {(submissionHistory.length > 0 || isSubmitted) && (
                <Segment>
                    <Header as="h3">Submission History</Header>
                    {renderSubmissionHistory()}
                </Segment>
            )}

            {otherSavedAnswers && !isSubmitted && !isRewrite && (
                <Message warning>
                    You have saved answers for other questions. Submitting this answer will delete those saved answers.
                </Message>
            )}

            {error && <Message negative>{error}</Message>}
            {success && <Message positive>{success}</Message>}

            {isSubmitted ? (
                <>
                    <Message info>
                        Your essay has been submitted and can no longer be edited.
                        {gradingInfo?.requiresRewrite && (
                            <div style={{ marginTop: '1rem' }}>
                                <Button primary onClick={handleStartRewrite}>Start Rewrite</Button>
                            </div>
                        )}
                    </Message>
                    <Segment>
                        <div dangerouslySetInnerHTML={{ __html: savedEssay }} />
                    </Segment>
                </>
            ) : isEditing ? (
                <>
                    {isRewrite && (
                        <>
                            <Header as="h3">Original Submission</Header>
                            <Segment>
                                <div dangerouslySetInnerHTML={{ __html: submissionHistory[0]?.essay || '' }} />
                            </Segment>
                            <Header as="h3">Your Rewrite</Header>
                        </>
                    )}
                    <Header as="h4">Word count: {wordCount(essay)} / 500</Header>
                    <ReactQuill theme="snow" value={essay} onChange={handleEssayChange} readOnly={isSubmitted} />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button primary onClick={handleSave} loading={isSaving} disabled={isSaving || isSubmitted} style={{ marginRight: '10px' }}>
                            Save Progress
                        </Button>
                        <Button color="green" onClick={() => setShowConfirm(true)} disabled={isSubmitting || isSubmitted || wordCount(essay) > 500}>
                            Submit {isRewrite ? 'Rewrite' : 'Essay'}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    {isRewrite && (
                        <>
                            <Header as="h3">Original Submission</Header>
                            <Segment>
                                <div dangerouslySetInnerHTML={{ __html: submissionHistory[0]?.essay || '' }} />
                            </Segment>
                            <Header as="h3">Your Rewrite Draft</Header>
                        </>
                    )}
                    <Segment>
                        <div dangerouslySetInnerHTML={{ __html: savedEssay }} />
                    </Segment>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button primary onClick={handleEdit} style={{ marginRight: '10px' }}>
                            Edit {isRewrite ? 'Rewrite' : 'Essay'}
                        </Button>
                    </div>
                </>
            )}

            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button onClick={handleBack} disabled={isSubmitting}>Back to Test</Button>
            </div> */}

            <Confirm
                open={showConfirm}
                content={`Are you sure you want to submit your ${isRewrite ? 'rewrite' : 'essay'}? You won't be able to edit it after submission.`}
                onCancel={() => setShowConfirm(false)}
                onConfirm={handleSubmit}
                confirmButton={
                    <Button positive loading={isSubmitting}>
                        OK
                    </Button>
                }
            />
        </div>
    );
};

export default StudentSubmissionOB;