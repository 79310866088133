import React, { useState, useEffect } from 'react';
import { Card, Button, Header, Segment, Label, Icon, Message } from 'semantic-ui-react';
import { collection, query, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../../firebase/config';
import { formatDate } from '../../../utils/dateUtils';
import ViewTest from './ViewTest';
import ViewCloseBookTest from './ViewCloseBookTest';
import GradeCloseBookList from './GradeCloseBookList';

interface BaseTest {
  id: string;
  testName: string;
  totalGrade: number;
  type: 'openBook' | 'closeBook' | 'verseMemorization';
}

interface OpenBookTest extends BaseTest {
  type: 'openBook';
  lastSubmissionDate: Date;
  questions: any[];
}

interface CloseBookTest extends BaseTest {
  type: 'closeBook';
  uploadEnabled: boolean;
  criteria: {
    shortAnswer: { description: string; grade: number };
    longAnswer: { description: string; grade: number };
  };
}

interface VerseMemorizationTest extends BaseTest {
  type: 'verseMemorization';
  uploadEnabled: boolean;
}

type Test = OpenBookTest | CloseBookTest | VerseMemorizationTest;

interface TestStatus {
  submissionStatus: string;
  gradingStatus: string;
  requiresRewrite?: boolean;
  isRewrite?: boolean;
  rewriteNumber?: number;
}

const ViewTests: React.FC<{ moduleId: string }> = ({ moduleId }) => {
  const [tests, setTests] = useState<Test[]>([]);
  const [testStatuses, setTestStatuses] = useState<Record<string, TestStatus>>({});
  const [selectedTest, setSelectedTest] = useState<string | null>(null);
  const [isStudent, setIsStudent] = useState(false);
  const [isAdminOrTeacher, setIsAdminOrTeacher] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchTestStatuses = async (fetchedTests: Test[]) => {
    const user = auth.currentUser;
    if (!user || !isStudent) return;

    const statuses: Record<string, TestStatus> = {};
    for (const test of fetchedTests) {
      const status = await fetchStudentSubmissionStatus(test.id, user.uid);
      statuses[test.id] = status;
    }
    setTestStatuses(statuses);
  };

  const fetchStudentSubmissionStatus = async (testId: string, studentId: string): Promise<TestStatus> => {
    const submissionDoc = await getDoc(doc(db, 'modules', moduleId, 'tests', testId, 'submissions', studentId));
    
    if (!submissionDoc.exists()) {
      return { submissionStatus: 'Not Submitted', gradingStatus: 'Pending' };
    }

    const submissionData = submissionDoc.data();
    
    // Handle open book test submission
    if (submissionData.answers) {
      if (submissionData.answers[0]?.submitted) {
        const answer = submissionData.answers[0];
        const gradingStatus = answer.gradingStatus?.toLowerCase();
        return { 
          submissionStatus: 'Submitted', 
          gradingStatus: gradingStatus === 'graded' ? 'Graded' : 'Pending',
          requiresRewrite: answer.requiresRewrite,
          isRewrite: answer.isRewrite,
          rewriteNumber: answer.rewriteNumber
        };
      }
    }
    // Handle close book test submission
    else if (submissionData.pdfUrl) {
      const gradingStatus = submissionData.gradingStatus?.toLowerCase();
      return { 
        submissionStatus: 'Submitted', 
        gradingStatus: gradingStatus === 'graded' ? 'Graded' : 'Pending',
        requiresRewrite: submissionData.requiresRewrite,
        isRewrite: submissionData.isRewrite,
        rewriteNumber: submissionData.rewriteNumber
      };
    }

    return { submissionStatus: 'Not Submitted', gradingStatus: 'Pending' };
  };

  useEffect(() => {
    const checkUserRoleAndFetchTests = async () => {
      const user = auth.currentUser;
      if (!user) return;

      try {
        // Check user role
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        const userIsStudent = userData?.role === 'student';
        const userIsAdminOrTeacher = ['admin', 'teacher'].includes(userData?.role || '');
        setIsStudent(userIsStudent);
        setIsAdminOrTeacher(userIsAdminOrTeacher);

        // Fetch tests
        const testsCollectionRef = collection(db, 'modules', moduleId, 'tests');
        const testsQuery = query(testsCollectionRef);
        const querySnapshot = await getDocs(testsQuery);

        const fetchedTests: Test[] = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            lastSubmissionDate: data.lastSubmissionDate?.toDate(),
          } as Test;
        });

        setTests(fetchedTests);
      } catch (error) {
        console.error('Error in checkUserRoleAndFetchTests:', error);
        setError('Failed to load tests');
      }
    };

    checkUserRoleAndFetchTests();
  }, [moduleId]);

  // Separate useEffect for fetching submission statuses
  useEffect(() => {
    const fetchSubmissionStatuses = async () => {
      if (!isStudent || !auth.currentUser || tests.length === 0) return;
      
      console.log('Fetching submission statuses for student:', auth.currentUser.uid);
      await fetchTestStatuses(tests);
    };

    fetchSubmissionStatuses();
  }, [isStudent, tests]);

  const handleToggleUpload = async (testId: string, currentStatus: boolean) => {
    try {
      await updateDoc(doc(db, 'modules', moduleId, 'tests', testId), {
        uploadEnabled: !currentStatus
      });

      // Update local state
      setTests(prevTests => 
        prevTests.map(test => 
          test.id === testId 
            ? { ...test, uploadEnabled: !currentStatus }
            : test
        )
      );
    } catch (error) {
      console.error('Error toggling upload status:', error);
      setError('Failed to update upload status');
    }
  };

  if (selectedTest) {
    const selectedTestData = tests.find(test => test.id === selectedTest);
    if (!selectedTestData) return null;

    if (selectedTestData.type === 'openBook') {
      return (
        <ViewTest
          test={selectedTestData}
          moduleId={moduleId}
          onBack={() => {
            setSelectedTest(null);
            if (isStudent) {
              fetchTestStatuses(tests);
            }
          }}
        />
      );
    } else {
      if (isStudent) {
        return (
          <ViewCloseBookTest
            test={selectedTestData}
            moduleId={moduleId}
            onBack={() => {
              setSelectedTest(null);
              if (isStudent) {
                fetchTestStatuses(tests);
              }
            }}
          />
        );
      } else {
        return (
          <GradeCloseBookList
            test={selectedTestData}
            moduleId={moduleId}
            onBack={() => setSelectedTest(null)}
          />
        );
      }
    }
  }

  const renderTestCard = (test: Test) => {
    const status = testStatuses[test.id] || { submissionStatus: 'Not Submitted', gradingStatus: 'Pending' };
    
    return (
      <Card fluid key={test.id}>
        <Card.Content>
          <Card.Header>
            {test.testName}
            {test.type !== 'openBook' && isStudent && (
              <Label 
                color={test.uploadEnabled ? 'green' : 'red'} 
                style={{ marginLeft: '1rem' }}
              >
                Upload {test.uploadEnabled ? 'Enabled' : 'Disabled'}
              </Label>
            )}
          </Card.Header>
          <Card.Meta>
            {test.type === 'openBook' ? (
              <div style={{ marginTop: '10px' }}>
                <strong>Last Submission Date:</strong> {formatDate(test.lastSubmissionDate!)}
              </div>
            ) : test.type === 'closeBook' ? (
              <div style={{ marginTop: '10px' }}>
                <div><strong>Short Answer Grade:</strong> {test.criteria?.shortAnswer.grade}</div>
                <div><strong>Long Answer Grade:</strong> {test.criteria?.longAnswer.grade}</div>
                <div><strong>Total Grade:</strong> {test.totalGrade}</div>
              </div>
            ) : (
              <div style={{ marginTop: '10px' }}>
                <strong>Total Grade:</strong> {test.totalGrade}
              </div>
            )}
          </Card.Meta>
          {isStudent && (
            <Card.Description>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Label color='blue'>
                  Submission Status: {status.isRewrite ? `Rewrite #${status.rewriteNumber} ` : ''}{status.submissionStatus}
                </Label>
                {status.submissionStatus === 'Submitted' && (
                  <Label 
                    color={status.gradingStatus === 'Graded' ? 
                      (status.requiresRewrite ? 'red' : 'green') : 
                      'orange'
                    }
                  >
                    {status.gradingStatus === 'Graded' ? 
                      (status.requiresRewrite ? 'Requires Rewrite' : 'Graded') : 
                      'Grading Pending'
                    }
                  </Label>
                )}
              </div>
            </Card.Description>
          )}
        </Card.Content>
        <Card.Content extra>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button 
              primary 
              onClick={() => setSelectedTest(test.id)}
              disabled={isStudent && test.type !== 'openBook' && !test.uploadEnabled && !status.requiresRewrite}
            >
              {isStudent ? 
                (test.type === 'openBook' ? 'View Test' : 'View/Upload Test') :
                (test.type === 'openBook' ? 'View/Grade Test' : 'View/Grade Submissions')
              }
            </Button>
            {isAdminOrTeacher && test.type !== 'openBook' && (
              <Button
                color={test.uploadEnabled ? 'red' : 'green'}
                onClick={() => handleToggleUpload(test.id, test.uploadEnabled || false)}
              >
                {test.uploadEnabled ? 'Disable Upload' : 'Enable Upload'}
              </Button>
            )}
          </div>
        </Card.Content>
      </Card>
    );
  };

  const groupedTests = tests.reduce((acc, test) => {
    // Group both closeBook and verseMemorization under closeBook
    const groupType = test.type === 'verseMemorization' ? 'closeBook' : test.type;
    acc[groupType] = [...(acc[groupType] || []), test];
    return acc;
  }, {} as Record<string, Test[]>);

  return (
    <div>
      {error && <Message negative>{error}</Message>}
      {Object.entries(groupedTests).map(([type, testsOfType]) => (
        <Segment key={type}>
          <Header as="h3">
            {type === 'openBook' ? 'Open Book Tests' : 'Close Book Tests'}
          </Header>
          {testsOfType.map(renderTestCard)}
        </Segment>
      ))}
    </div>
  );
};

export default ViewTests;