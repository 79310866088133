import React, { useState, useEffect } from 'react';
import { Button, Header, Segment, Message, Label, Icon, Loader } from 'semantic-ui-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../../firebase/config';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { formatDate } from '../../../utils/dateUtils';

interface Test {
  id: string;
  testName: string;
  type: 'closeBook' | 'verseMemorization';
  totalGrade: number;
  uploadEnabled: boolean;
  criteria?: {
    shortAnswer: { description: string; grade: number };
    longAnswer: { description: string; grade: number };
  };
}

interface ViewCloseBookTestProps {
  test: Test;
  moduleId: string;
  onBack: () => void;
}

const ViewCloseBookTest: React.FC<ViewCloseBookTestProps> = ({ test, moduleId, onBack }) => {
  const [user] = useAuthState(auth);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [submission, setSubmission] = useState<any>(null);
  const [userRole, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      try {
        // Get user role
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        setUserRole(userData?.role || null);

        // Get submission if exists
        const submissionDoc = await getDoc(
          doc(db, 'modules', moduleId, 'tests', test.id, 'submissions', user.uid)
        );
        
        if (submissionDoc.exists()) {
          setSubmission(submissionDoc.data());
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data');
      }
    };

    fetchData();
  }, [user, moduleId, test.id]);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!user || !event.target.files || !event.target.files[0]) return;

    const file = event.target.files[0];
    if (file.type !== 'application/pdf') {
      setError('Please upload a PDF file');
      return;
    }

    setIsUploading(true);
    setError(null);
    setSuccess(null);

    try {
      // Upload file to Firebase Storage
      const storage = getStorage();
      const storageRef = ref(storage, `submissions/${moduleId}/${test.id}/${user.uid}/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Create submission document
      const submissionRef = doc(db, 'modules', moduleId, 'tests', test.id, 'submissions', user.uid);
      await setDoc(submissionRef, {
        studentId: user.uid,
        submittedAt: new Date(),
        pdfUrl: downloadURL,
        fileName: file.name,
        gradingStatus: 'pending',
        type: test.type
      });

      setSuccess('PDF uploaded successfully');
      setSubmission({
        pdfUrl: downloadURL,
        fileName: file.name,
        submittedAt: new Date(),
        gradingStatus: 'pending'
      });
    } catch (err) {
      console.error('Error uploading file:', err);
      setError('Failed to upload file. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const renderGradingInfo = () => {
    if (!submission?.gradingStatus || submission.gradingStatus === 'pending') return null;

    return (
      <Segment>
        <Header as="h3">Grading Results</Header>
        <div>
          {test.type === 'closeBook' ? (
            <>
              <p><strong>Short Answer Questions:</strong> {submission.grades?.shortAnswer || 0}/{test.criteria?.shortAnswer.grade}</p>
              <p><strong>Long Answer Questions:</strong> {submission.grades?.longAnswer || 0}/{test.criteria?.longAnswer.grade}</p>
            </>
          ) : (
            <p><strong>Total Grade:</strong> {submission.grade || 0}/{test.totalGrade}</p>
          )}
        </div>
        {submission.graderComments && (
          <>
            <Header as="h4">Grader Comments</Header>
            <p>{submission.graderComments}</p>
          </>
        )}
      </Segment>
    );
  };

  return (
    <div>
      <Button
        color='grey'
        icon
        labelPosition='left'
        onClick={onBack}
        style={{ marginBottom: '1rem' }}
      >
        <Icon name='arrow left' />
        Back to Tests
      </Button>

      <Header as="h2">{test.testName}</Header>
      
      {error && <Message negative>{error}</Message>}
      {success && <Message positive>{success}</Message>}

      <Segment>
        <Header as="h3">Test Details</Header>
        {test.type === 'closeBook' ? (
          <>
            <p><strong>Short Answer Questions Grade:</strong> {test.criteria?.shortAnswer.grade}</p>
            <p><strong>Long Answer Questions Grade:</strong> {test.criteria?.longAnswer.grade}</p>
          </>
        ) : (
          <p><strong>Total Grade:</strong> {test.totalGrade}</p>
        )}
        <Label color={test.uploadEnabled ? 'green' : 'red'}>
          Upload {test.uploadEnabled ? 'Enabled' : 'Disabled'}
        </Label>
      </Segment>

      {userRole === 'student' && (
        <Segment>
          <Header as="h3">Submit Your Test</Header>
          {!submission ? (
            test.uploadEnabled ? (
              <>
                <p>Please upload your test paper in PDF format.</p>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileUpload}
                  disabled={isUploading}
                  style={{ display: 'none' }}
                  id="pdf-upload"
                />
                <label htmlFor="pdf-upload">
                  <Button
                    as="span"
                    primary
                    loading={isUploading}
                    disabled={isUploading}
                  >
                    Upload PDF
                  </Button>
                </label>
                {isUploading && (
                  <Segment basic>
                    <Loader active inline='centered'>Uploading...</Loader>
                  </Segment>
                )}
              </>
            ) : (
              <Message warning>
                Upload is currently disabled. Please wait for the teacher to enable it.
              </Message>
            )
          ) : (
            <div>
              <Message info>
                <Message.Header>Test Submitted</Message.Header>
                <p>You have already submitted your test paper.</p>
                <p>Submitted on: {formatDate(submission.submittedAt)}</p>
                <Button
                  as="a"
                  href={submission.pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Submitted PDF
                </Button>
              </Message>
            </div>
          )}
        </Segment>
      )}

      {submission && renderGradingInfo()}
    </div>
  );
};

export default ViewCloseBookTest; 