import React, { useState, useEffect } from 'react';
import {
    Header,
    Segment,
    Button,
    Form,
    Label,
    Icon,
    Message,
    Divider,
    Confirm,
    Tab,
    List
} from 'semantic-ui-react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import parse from 'html-react-parser';
import { formatDate } from '../../../utils/dateUtils';

interface Criteria {
    description: string;
    grade: number;
    maxGrade: number;
    actualGrade?: number;
}

interface Question {
    text: string;
    criteria: Criteria[];
}

interface SubmissionHistory {
    essay: string;
    submittedAt: Date;
    gradingStatus: 'graded';
    grade: number;
    criteriaGrades: Criteria[];
    graderComments: string;
    requiresRewrite: boolean;
    rewriteNumber: number;
}

interface GradeIndividualOBProps {
    moduleId: string;
    testId: string;
    submissionId: string;
    onBack: () => void;
}

const GradeIndividualOB: React.FC<GradeIndividualOBProps> = ({
    moduleId,
    testId,
    submissionId,
    onBack
}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [testQuestion, setTestQuestion] = useState<Question | null>(null);
    const [submission, setSubmission] = useState<any>(null);
    const [grades, setGrades] = useState<Record<number, number>>({});
    const [comments, setComments] = useState<string>('');
    const [totalGrade, setTotalGrade] = useState<number>(0);
    const [showConfirm, setShowConfirm] = useState(false);
    const [isGraded, setIsGraded] = useState(false);
    const [submissionHistory, setSubmissionHistory] = useState<SubmissionHistory[]>([]);
    const [activeSubmissionTab, setActiveSubmissionTab] = useState(0);
    const [isRewrite, setIsRewrite] = useState(false);
    const [rewriteNumber, setRewriteNumber] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch test details
                const testDoc = await getDoc(doc(db, 'modules', moduleId, 'tests', testId));
                if (!testDoc.exists()) throw new Error('Test not found');

                // Fetch submission
                const submissionDoc = await getDoc(
                    doc(db, 'modules', moduleId, 'tests', testId, 'submissions', submissionId)
                );
                if (!submissionDoc.exists()) throw new Error('Submission not found');

                const submissionData = submissionDoc.data();
                const answer = submissionData.answers[0];
                const testData = testDoc.data();

                // Get the specific question from the test
                const question = testData.questions[answer.questionIndex];
                setTestQuestion(question);
                setSubmission({ ...submissionData, answer });
                setIsGraded(answer.gradingStatus === 'graded');
                setIsRewrite(answer.isRewrite || false);
                setRewriteNumber(answer.rewriteNumber || 0);

                // Set submission history
                if (answer.previousSubmissions) {
                    setSubmissionHistory(answer.previousSubmissions);
                }

                // If already graded, set the existing grades
                if (answer.gradingStatus === 'graded') {
                    const gradeObj: Record<number, number> = {};
                    answer.criteriaGrades.forEach((cg: any, index: number) => {
                        gradeObj[index] = cg.actualGrade;
                    });
                    setGrades(gradeObj);
                    setComments(answer.graderComments || '');
                    setTotalGrade(answer.grade || 0);
                } else {
                    // Initialize grades as empty object instead of zeros
                    setGrades({});
                }
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Failed to load submission data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [moduleId, testId, submissionId]);

    const handleGradeChange = (criteriaIndex: number, grade: number) => {
        if (!testQuestion) return;

        const maxAllowedGrade = testQuestion.criteria[criteriaIndex].grade;
        // Don't clamp the value, just store it as-is
        const newGrades = { ...grades, [criteriaIndex]: grade };
        setGrades(newGrades);

        // Calculate total from valid grades only
        const total = Object.entries(newGrades).reduce((sum, [_, g]) => {
            const isValidGrade = typeof g === 'number' && g >= 0;
            return sum + (isValidGrade ? g : 0);
        }, 0);
        setTotalGrade(total);
    };

    const isSubmitDisabled = () => {
        if (!testQuestion) return true;

        // Check if all criteria have valid grades and comments exist
        const allCriteriaGraded = testQuestion.criteria.every((criterion, index) => {
            const grade = grades[index];
            return typeof grade === 'number' &&
                grade >= 0 &&
                grade <= criterion.grade;
        });

        const hasComments = comments.trim().length > 0;
        const hasNoErrors = testQuestion.criteria.every((criterion, index) => {
            const grade = grades[index];
            return grade <= criterion.grade;
        });

        return !allCriteriaGraded || !hasComments || !hasNoErrors;
    };

    const handleSubmitGrade = async () => {
        if (!testQuestion) return;

        try {
            const submissionRef = doc(
                db,
                'modules',
                moduleId,
                'tests',
                testId,
                'submissions',
                submissionId
            );

            const criteriaWithGrades = testQuestion.criteria.map((criterion, index) => ({
                description: criterion.description,
                grade: criterion.grade,
                actualGrade: grades[index] || 0
            }));

            // Get current submission data
            const currentSubmissionDoc = await getDoc(submissionRef);
            const currentData = currentSubmissionDoc.data();
            const currentAnswer = currentData?.answers[0];

            // Update the current submission
            await updateDoc(submissionRef, {
                answers: [{
                    ...currentAnswer,
                    gradingStatus: 'graded',
                    grade: totalGrade,
                    criteriaGrades: criteriaWithGrades,
                    graderComments: comments,
                    requiresRewrite: totalGrade < 13,
                    gradedAt: new Date()
                }]
            });

            setIsGraded(true);
            setShowConfirm(false);
        } catch (err) {
            console.error('Error submitting grade:', err);
            setError('Failed to submit grade');
        }
    };

    const renderSubmissionHistory = () => {
        if (!submission) return null;

        const allSubmissions = [
            ...submissionHistory,
            {
                essay: submission.answer.essay,
                submittedAt: submission.answer.submittedAt,
                gradingStatus: submission.answer.gradingStatus,
                grade: submission.answer.grade,
                criteriaGrades: submission.answer.criteriaGrades,
                graderComments: submission.answer.graderComments,
                requiresRewrite: submission.answer.requiresRewrite,
                rewriteNumber: submission.answer.rewriteNumber
            }
        ];

        return (
            <Tab
                panes={allSubmissions.map((submission, index) => ({
                    menuItem: index === 0 ? 'Original Submission' : `Rewrite ${index}`,
                    render: () => (
                        <Tab.Pane>
                            <Segment>
                                <Header as="h4">
                                    {index === 0 ? 'Original Submission' : `Rewrite ${index}`}
                                    {submission.gradingStatus === 'graded' && (
                                        <Label color={submission.requiresRewrite ? 'red' : 'green'} style={{ marginLeft: '1rem' }}>
                                            {submission.requiresRewrite ? 'Requires Rewrite' : 'Graded'}
                                        </Label>
                                    )}
                                    <Label style={{ marginLeft: '1rem' }}>
                                        Submitted: {formatDate(submission.submittedAt)}
                                    </Label>
                                </Header>
                                <div className="rich-text-content">
                                    {parse(submission.essay)}
                                </div>
                                {submission.gradingStatus === 'graded' && (
                                    <>
                                        <Header as="h4">Grade: {submission.grade}</Header>
                                        <Header as="h4">Criteria Grades</Header>
                                        <List>
                                            {submission.criteriaGrades?.map((criterion: Criteria, idx: number) => (
                                                <List.Item key={idx}>
                                                    <List.Content>
                                                        <List.Header>{criterion.description}</List.Header>
                                                        <List.Description>
                                                            Grade: {criterion.actualGrade} / {criterion.grade}
                                                        </List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            ))}
                                        </List>
                                        <Header as="h4">Grader Comments</Header>
                                        <Segment>
                                            {submission.graderComments}
                                        </Segment>
                                    </>
                                )}
                            </Segment>
                        </Tab.Pane>
                    )
                }))}
                onTabChange={(_, data) => setActiveSubmissionTab(data.activeIndex as number)}
                activeIndex={activeSubmissionTab}
            />
        );
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <Message negative>{error}</Message>;
    if (!testQuestion || !submission) return <Message negative>No data found</Message>;

    return (
        <div>
            <Button
                color='grey'
                icon
                labelPosition='left'
                onClick={onBack}
                style={{ marginBottom: '1rem' }}
            >
                <Icon name='arrow left' />
                Back to Submissions
            </Button>

            <Header as='h2'>
                Grade Submission #{submissionId.slice(-6)}
                {isRewrite && (
                    <Label color="blue" style={{ marginLeft: '1rem' }}>
                        Rewrite #{rewriteNumber}
                    </Label>
                )}
            </Header>

            {submissionHistory.length > 0 && (
                <Segment>
                    <Header as="h3">Submission History</Header>
                    {renderSubmissionHistory()}
                </Segment>
            )}

            <Segment>
                <Header as='h3'>Question</Header>
                <div className="rich-text-content">
                    {parse(testQuestion.text)}
                </div>
            </Segment>

            <Segment>
                <Header as='h3'>Current Submission</Header>
                <div className="rich-text-content">
                    {parse(submission.answer.essay)}
                </div>
            </Segment>

            <Segment>
                <Header as='h3'>Grading Criteria</Header>
                <Form>
                    {testQuestion.criteria.map((criterion, index) => (
                        <Form.Field key={index}>
                            <Label>
                                {criterion.description}
                                <Label.Detail>Max Grade: {criterion.grade}</Label.Detail>
                            </Label>
                            <Form.Input
                                type="number"
                                min={0}
                                value={grades[index] || ''}
                                onChange={(e) => handleGradeChange(index, Number(e.target.value))}
                                disabled={isGraded}
                                style={isGraded ? {
                                    opacity: 1,
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    border: '1px solid #ddd'
                                } : undefined}
                                error={
                                    grades[index] > criterion.grade
                                        ? {
                                            content: `Maximum grade allowed is ${criterion.grade}`,
                                            pointing: 'above'
                                        }
                                        : null
                                }
                            />
                        </Form.Field>
                    ))}

                    <Form.TextArea
                        label="Grader Comments"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        disabled={isGraded}
                        style={isGraded ? {
                            opacity: 1,
                            backgroundColor: '#fff',
                            color: '#000',
                            border: '1px solid #ddd'
                        } : undefined}
                    />

                    <Divider />

                    <div style={{ marginBottom: '1rem' }}>
                        <Header as='h4'>
                            Total Grade: {totalGrade}
                            {totalGrade < 13 && (
                                <Label color='red' style={{ marginLeft: '1rem' }}>
                                    Requires Rewrite
                                </Label>
                            )}
                        </Header>
                    </div>

                    {!isGraded && (
                        <Button
                            primary
                            onClick={() => setShowConfirm(true)}
                            disabled={isSubmitDisabled()}
                        >
                            Submit Grade
                        </Button>
                    )}
                </Form>
            </Segment>

            <Confirm
                open={showConfirm}
                content="Are you sure you want to submit this grade? This action cannot be undone."
                onCancel={() => setShowConfirm(false)}
                onConfirm={handleSubmitGrade}
            />
        </div>
    );
};

export default GradeIndividualOB;