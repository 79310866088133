import { Timestamp } from 'firebase/firestore';

export const formatDate = (date: Timestamp | Date | null | undefined): string => {
  if (!date) return 'N/A';

  let dateObj: Date;

  if (date instanceof Timestamp) {
    dateObj = date.toDate();
  } else if (date instanceof Date) {
    dateObj = date;
  } else {
    return 'Invalid Date';
  }

  // Check if the dateObj is a valid date
  if (isNaN(dateObj.getTime())) {
    return 'Invalid Date';
  }

  return dateObj.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};