import React, { useState, useEffect } from 'react';
import { Header, Segment, List, Button, Loader, Grid, Accordion, Icon, Message, Label, Input } from 'semantic-ui-react';
import { collection, doc, getDoc, getDocs, query, where, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebase/config';
import { formatDate } from '../../../utils/dateUtils';
import parse from 'html-react-parser';
import StudentSubmissionOB from './StudentSubmissionOB';
import GradeOB from './GradeOB';
import GradeIndividualOB from './GradeIndividualOB';
import { Timestamp } from 'firebase/firestore';

interface Criteria {
    description: string;
    grade: number;
}

interface Question {
    text: string;
    criteria: Criteria[];
}

interface Test {
    id: string;
    testName: string;
    lastSubmissionDate: Date;
    type: 'openBook' | 'closeBook';
    questions: Question[];
    totalGrade: number;
}

interface Answer {
    essay: string;
    questionIndex: number;
    submitted: boolean;
    submittedAt?: Date;
    lastSaved?: Date;
    gradingStatus?: 'pending' | 'graded';
    grade?: number;
    isRewrite?: boolean;
    rewriteNumber?: number;
    requiresRewrite?: boolean;
    previousSubmissions?: {
        essay: string;
        submittedAt: Date;
        gradingStatus: 'graded';
        grade: number;
        criteriaGrades: Criteria[];
        graderComments: string;
        requiresRewrite: boolean;
        rewriteNumber: number;
    }[];
}

interface ViewTestProps {
    test: Test;
    moduleId: string;
    onBack: () => void;
    onTestUpdate?: (updatedTest: Test) => void;
}

const ViewTest: React.FC<ViewTestProps> = ({ test, moduleId, onBack, onTestUpdate }) => {
    const [user, loading] = useAuthState(auth);
    const [testDetails, setTestDetails] = useState<Test | null>(null);
    const [userRole, setUserRole] = useState<string | null>(null);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [isSubmissionAllowed, setIsSubmissionAllowed] = useState<boolean | null>(null);
    const [submission, setSubmission] = useState<Answer[] | null>(null);
    const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null);
    const [showGrading, setShowGrading] = useState(false);
    const [selectedSubmission, setSelectedSubmission] = useState<string | null>(null);
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [newLastSubmissionDate, setNewLastSubmissionDate] = useState('');
    const [newLastSubmissionTime, setNewLastSubmissionTime] = useState('');

    const fetchSubmission = async () => {
        if (user) {
            const submissionRef = doc(db, 'modules', moduleId, 'tests', test.id, 'submissions', user.uid);
            const submissionDoc = await getDoc(submissionRef);
            if (submissionDoc.exists()) {
                const data = submissionDoc.data();
                const answers = data.answers || [];
                setSubmission(answers);
            } else {
                setSubmission(null);
            }
        }
    };

    useEffect(() => {
        const fetchTestDetails = async () => {
            const testDoc = await getDoc(doc(db, 'modules', moduleId, 'tests', test.id));
            if (testDoc.exists()) {
                setTestDetails(testDoc.data() as Test);
            }
        };

        const fetchUserRole = async () => {
            if (user) {
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    setUserRole(userDoc.data().role);
                }
            }
        };

        // const fetchSubmission = async () => {
        //     if (user) {
        //         const submissionRef = doc(db, 'modules', moduleId, 'tests', test.id, 'submissions', user.uid);
        //         const submissionDoc = await getDoc(submissionRef);
        //         if (submissionDoc.exists()) {
        //             const data = submissionDoc.data();
        //             const answers = data.answers || [];
        //             setSubmission(answers);
        //         }else {
        //             setSubmission(null);
        //         }
        //     }
        // };

        fetchTestDetails();
        fetchUserRole();
        fetchSubmission();
    }, [test.id, moduleId, user]);

    useEffect(() => {
        const checkSubmissionAllowed = async () => {
            if (user && userRole === 'student' && testDetails) {
                const now = new Date();
                const lastSubmissionDate = testDetails.lastSubmissionDate instanceof Timestamp 
                    ? testDetails.lastSubmissionDate.toDate() 
                    : new Date(testDetails.lastSubmissionDate);

                const isBeforeDeadline = now.getTime() < lastSubmissionDate.getTime();

                if (isBeforeDeadline) {
                    setIsSubmissionAllowed(true);
                } else {
                    // Check if there's an exception for this student
                    const exceptionsRef = collection(db, 'modules', moduleId, 'tests', test.id, 'exceptions');
                    const q = query(exceptionsRef, where('studentId', '==', user.uid));
                    const querySnapshot = await getDocs(q);
                    setIsSubmissionAllowed(!querySnapshot.empty);
                }
            }
        };

        if (user && userRole && testDetails && isSubmissionAllowed === null) {
            checkSubmissionAllowed();
        }
    }, [user, userRole, testDetails, isSubmissionAllowed, moduleId, test.id]);

    useEffect(() => {
        if (selectedQuestion === null) {
            fetchSubmission();
        }
    }, [selectedQuestion]);

    const handleAccordionClick = (index: number) => {
        setActiveIndex(activeIndex === index ? -1 : index);
    };

    const handleAnswerQuestion = (questionIndex: number) => {
        setSelectedQuestion(questionIndex);
    };

    const getSubmissionStatus = () => {
        if (!submission) return null;
        const submittedAnswers = submission.filter(ans => ans.submitted);
        if (submittedAnswers.length > 0) {
            const latestAnswer = submittedAnswers[0];
            const gradingStatus = latestAnswer.gradingStatus?.toLowerCase();
            return {
                submittedAt: latestAnswer.submittedAt,
                gradingStatus,
                requiresRewrite: latestAnswer.requiresRewrite,
                isRewrite: latestAnswer.isRewrite,
                rewriteNumber: latestAnswer.rewriteNumber
            };
        }
        return null;
    };

    const handleGradeSubmission = (submissionId: string) => {
        setSelectedSubmission(submissionId);
        setShowGrading(false); // Hide the GradeOB component when showing individual grading
    };

    const handleBackFromGradeIndividual = () => {
        setSelectedSubmission(null);
        setShowGrading(true); // Show the GradeOB component when going back from individual grading
    };

    const submissionStatus = getSubmissionStatus();
    const hasSubmittedAnyAnswer = submission?.some(ans => ans.submitted) || false;

    const handleDateEdit = async () => {
        if (!isEditingDate) {
            const date = testDetails!.lastSubmissionDate instanceof Timestamp 
                ? testDetails!.lastSubmissionDate.toDate() 
                : new Date(testDetails!.lastSubmissionDate);
            
            setNewLastSubmissionDate(date.toISOString().split('T')[0]);
            setNewLastSubmissionTime(date.toTimeString().slice(0, 5));
            setIsEditingDate(true);
            return;
        }

        try {
            const lastSubmissionTimestamp = Timestamp.fromDate(
                new Date(`${newLastSubmissionDate}T${newLastSubmissionTime}:00`)
            );

            const testRef = doc(db, 'modules', moduleId, 'tests', test.id);
            await updateDoc(testRef, {
                lastSubmissionDate: lastSubmissionTimestamp
            });
            
            // Update local state with the Timestamp
            const updatedTest = {
                ...testDetails!,
                lastSubmissionDate: lastSubmissionTimestamp.toDate()
            };
            setTestDetails(updatedTest);
            setIsEditingDate(false);

            // Notify parent component of the update
            if (onTestUpdate) {
                onTestUpdate(updatedTest as Test);
            }
        } catch (error) {
            console.error('Error updating submission date:', error);
        }
    };

    if (loading || !testDetails) {
        return <Loader active>Loading test details...</Loader>;
    }

    if (selectedQuestion !== null) {
        return (
            <StudentSubmissionOB
                moduleId={moduleId}
                testId={test.id}
                questionIndex={selectedQuestion}
                question={testDetails!.questions[selectedQuestion]}
                onBack={() => setSelectedQuestion(null)}
            />
        );
    }

    if (selectedSubmission) {
        return (
            <GradeIndividualOB
                moduleId={moduleId}
                testId={test.id}
                submissionId={selectedSubmission}
                onBack={handleBackFromGradeIndividual}
            />
        );
    }

    if (showGrading) {
        return (
            <GradeOB
                moduleId={moduleId}
                testId={test.id}
                onBack={() => setShowGrading(false)}
                onGradeSubmission={handleGradeSubmission}
            />
        );
    }

    return (
        <div>
            <Button color='grey' icon labelPosition='left' onClick={onBack} style={{ marginBottom: '1rem' }}>
                <Icon name='arrow left' />
                Back to Tests
            </Button>
            <div style={{ marginBottom: '2rem' }}></div>
            <Grid columns={2} stackable>
                <Grid.Column width={12}>
                    <Header as="h2">{testDetails.testName}</Header>
                    <Segment>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <p><strong>Last Submission Date:</strong> {
                                    isEditingDate ? (
                                        <span style={{ display: 'inline-flex', alignItems: 'center', gap: '1rem' }}>
                                            <Input 
                                                type="date" 
                                                value={newLastSubmissionDate}
                                                onChange={(e) => setNewLastSubmissionDate(e.target.value)}
                                                style={{ width: '150px' }}
                                            />
                                            <Input 
                                                type="time" 
                                                value={newLastSubmissionTime}
                                                onChange={(e) => setNewLastSubmissionTime(e.target.value)}
                                                style={{ width: '100px' }}
                                            />
                                        </span>
                                    ) : (
                                        formatDate(testDetails.lastSubmissionDate)
                                    )
                                }
                                {userRole === 'admin' && (
                                    <Button
                                        icon
                                        size='tiny'
                                        style={{ marginLeft: '1rem' }}
                                        onClick={handleDateEdit}
                                    >
                                        <Icon name={isEditingDate ? 'save' : 'edit'} />
                                    </Button>
                                )}
                                </p>
                                <p><strong>Total Grade:</strong> {testDetails.totalGrade}</p>
                                <p><strong>Grading Deadline:</strong> {formatDate(
                                    testDetails.lastSubmissionDate instanceof Timestamp 
                                        ? new Date(testDetails.lastSubmissionDate.toDate().getTime() + (14 * 24 * 60 * 60 * 1000))
                                        : new Date(new Date(testDetails.lastSubmissionDate).getTime() + (14 * 24 * 60 * 60 * 1000))
                                )}</p>
                            </div>
                            {submissionStatus && (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <Label style={{ backgroundColor: '#2e7d32', color: 'white' }}>
                                        Submission Status: {submissionStatus.isRewrite ? `Rewrite #${submissionStatus.rewriteNumber}` : ''} Submitted on {formatDate(submissionStatus.submittedAt!)}
                                    </Label>
                                    <Label 
                                        color={submissionStatus.gradingStatus === 'graded' ? 
                                            (submissionStatus.requiresRewrite ? 'red' : 'green') : 
                                            'orange'
                                        } 
                                        style={{ marginTop: '1rem' }}
                                    >
                                        {submissionStatus.gradingStatus === 'graded' ? 
                                            (submissionStatus.requiresRewrite ? 'Requires Rewrite' : 'Graded') : 
                                            'Grading Pending'
                                        }
                                    </Label>
                                </div>
                            )}
                        </div>
                    </Segment>

                    <Header as="h3">Questions</Header>
                    <Accordion fluid styled>
                        {testDetails.questions.map((question, index) => {
                            const answer = submission?.find((ans: any) => ans.questionIndex === index);
                            return (
                                <React.Fragment key={index}>
                                    <Accordion.Title
                                        active={activeIndex === index}
                                        index={index}
                                        onClick={() => handleAccordionClick(index)}
                                    >
                                        <Header as="h4">
                                            Question {index + 1}
                                            {answer && answer.submitted && (
                                                <span style={{ float: 'right', fontSize: '0.9em', color: 'green' }}>
                                                    {answer.isRewrite ? `Rewrite #${answer.rewriteNumber} ` : ''}
                                                    Submitted
                                                </span>
                                            )}
                                            {answer && !answer.submitted && (
                                                <span style={{ float: 'right', fontSize: '0.9em', color: 'blue' }}>
                                                    {answer.isRewrite ? `Rewrite #${answer.rewriteNumber} ` : ''}
                                                    Saved: {formatDate(answer.lastSaved!)}
                                                </span>
                                            )}
                                        </Header>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex === index}>
                                        <div className="rich-text-content">
                                            {parse(question.text)}
                                        </div>
                                        <Header as="h5">Criteria</Header>
                                        <List>
                                            {question.criteria.map((criterion, cIndex) => (
                                                <List.Item key={cIndex}>
                                                    <List.Content>
                                                        <List.Header>{criterion.description}</List.Header>
                                                        <List.Description>Grade: {criterion.grade}</List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            ))}
                                        </List>
                                        {userRole === 'student' && (
                                            <Button
                                                primary={!answer || !answer.submitted}
                                                secondary={answer && answer.submitted}
                                                onClick={() => handleAnswerQuestion(index)}
                                                style={{ marginTop: '1rem' }}
                                                disabled={
                                                    (!isSubmissionAllowed && (!answer || !answer.submitted)) || 
                                                    (!submissionStatus?.requiresRewrite && hasSubmittedAnyAnswer && !answer?.submitted)
                                                }
                                            >
                                                {answer && answer.submitted ? 'View Your Submission' : (
                                                    submissionStatus?.requiresRewrite ? 'Answer This Question (Rewrite)' : 'Answer This Question'
                                                )}
                                            </Button>
                                        )}
                                        {userRole === 'student' && !isSubmissionAllowed && !answer?.isRewrite && !submissionStatus?.requiresRewrite && (
                                            <Message warning>
                                                Submission deadline has passed. Please contact admin for extension.
                                            </Message>
                                        )}
                                        {userRole === 'student' && answer?.submitted && answer?.questionIndex !== index && submissionStatus?.requiresRewrite && (
                                            <Message info>
                                                Note: Your rewrite is answering a different question than your original submission.
                                            </Message>
                                        )}
                                    </Accordion.Content>
                                </React.Fragment>
                            );
                        })}
                    </Accordion>
                </Grid.Column>

                <Grid.Column width={4}>
                    {(userRole === 'grader' || userRole === 'teacher' || userRole === 'admin') && (
                        <Button
                            primary
                            fluid
                            onClick={() => setShowGrading(true)}
                        >
                            Grade Submissions
                        </Button>
                    )}
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default ViewTest;