import React, { useState } from 'react';
import { Form, Button, Input, Segment, Message, Tab, Label } from 'semantic-ui-react';
import { auth, db } from '../../../firebase/config';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';

interface CloseBookTestProps {
  moduleId: string;
  onTestCreated: () => void;
}

const CloseBookTest: React.FC<CloseBookTestProps> = ({ moduleId, onTestCreated }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [testName, setTestName] = useState('');
  const [shortAnswerGrade, setShortAnswerGrade] = useState<number>(0);
  const [longAnswerGrade, setLongAnswerGrade] = useState<number>(0);
  const [totalGrade, setTotalGrade] = useState<number>(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  const handleCloseBookSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser!.uid));
      const userData = userDoc.data();
      const createdBy = userData?.initiatedName || `${userData?.firstName} ${userData?.lastName}`;

      const testData = {
        moduleId,
        testName,
        type: 'closeBook',
        criteria: {
          shortAnswer: {
            description: 'Short Answer Questions',
            grade: shortAnswerGrade
          },
          longAnswer: {
            description: 'Long Answer Questions',
            grade: longAnswerGrade
          }
        },
        totalGrade: shortAnswerGrade + longAnswerGrade,
        uploadEnabled: false,
        createdAt: new Date(),
        createdBy,
      };

      const moduleRef = doc(db, 'modules', moduleId);
      const testsCollectionRef = collection(moduleRef, 'tests');
      await addDoc(testsCollectionRef, testData);

      setSuccessMessage('Close Book Test created successfully!');
      onTestCreated();
    } catch (error) {
      console.error('Error creating test:', error);
      setError('Failed to create test. Please try again.');
    }
  };

  const handleVerseMemorizationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser!.uid));
      const userData = userDoc.data();
      const createdBy = userData?.initiatedName || `${userData?.firstName} ${userData?.lastName}`;

      const testData = {
        moduleId,
        testName,
        type: 'verseMemorization',
        totalGrade,
        uploadEnabled: false,
        createdAt: new Date(),
        createdBy,
      };

      const moduleRef = doc(db, 'modules', moduleId);
      const testsCollectionRef = collection(moduleRef, 'tests');
      await addDoc(testsCollectionRef, testData);

      setSuccessMessage('Verse Memorization Test created successfully!');
      onTestCreated();
    } catch (error) {
      console.error('Error creating test:', error);
      setError('Failed to create test. Please try again.');
    }
  };

  const panes = [
    {
      menuItem: 'Close Book Test',
      render: () => (
        <Tab.Pane>
          <Form onSubmit={handleCloseBookSubmit}>
            {successMessage && <Message positive>{successMessage}</Message>}
            {error && <Message negative>{error}</Message>}
            
            <Form.Field>
              <label>Test Name</label>
              <Input
                value={testName}
                onChange={(e) => setTestName(e.target.value)}
                placeholder="Enter test name"
                required
              />
            </Form.Field>

            <Segment>
              <Label attached='top'>Grading Criteria</Label>
              <Form.Field>
                <label>Short Answer Questions Grade</label>
                <Input
                  type="number"
                  value={shortAnswerGrade}
                  onChange={(e) => setShortAnswerGrade(Number(e.target.value))}
                  min={0}
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Long Answer Questions Grade</label>
                <Input
                  type="number"
                  value={longAnswerGrade}
                  onChange={(e) => setLongAnswerGrade(Number(e.target.value))}
                  min={0}
                  required
                />
              </Form.Field>

              <Label>Total Grade: {shortAnswerGrade + longAnswerGrade}</Label>
            </Segment>

            <Button type="submit" primary>
              Create Close Book Test
            </Button>
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Verse Memorization',
      render: () => (
        <Tab.Pane>
          <Form onSubmit={handleVerseMemorizationSubmit}>
            {successMessage && <Message positive>{successMessage}</Message>}
            {error && <Message negative>{error}</Message>}
            
            <Form.Field>
              <label>Test Name</label>
              <Input
                value={testName}
                onChange={(e) => setTestName(e.target.value)}
                placeholder="Enter test name"
                required
              />
            </Form.Field>

            <Form.Field>
              <label>Total Grade</label>
              <Input
                type="number"
                value={totalGrade}
                onChange={(e) => setTotalGrade(Number(e.target.value))}
                min={0}
                required
              />
            </Form.Field>

            <Button type="submit" primary>
              Create Verse Memorization Test
            </Button>
          </Form>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      <Tab
        panes={panes}
        activeIndex={activeTab}
        onTabChange={(_, data) => {
          setActiveTab(data.activeIndex as number);
          setTestName('');
          setError('');
          setSuccessMessage('');
        }}
      />
    </div>
  );
};

export default CloseBookTest; 